<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/commentus/lists">Quản lý Ý kiến khách hàng</a>
      </li>

      <li class="breadcrumb-item">Gửi mail   </li>
    </ol>

    <form method="post" @submit.prevent="mySubmit" id="sendform">
      <input type="hidden" :value="from.id" name="id" />
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
           
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong> Send to: {{from.name}}  (  {{from.email}}  )   </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                     
                    
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="address">Tiêu đề </label>
                          <input
                            class="form-control"
                            name="subject"
                            type="text"
                            v-model="subject"
                          />
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content"> Nội dung </label>
                          <ckeditor  :config="editorConfig" v-model="content"
                          @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                          <p class="text-danger">{{ error.content }}</p>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fad fa-save"></i> Gửi mail 
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      from: {},
      id: this.$route.params.id,
      is_load: false,
      content: "",
      subject: "",
      error: {
        name: "",
        name_EN: "",
      },
      editorConfig:window.editorConfig
    };
  },
  mounted() {
    axios
      .get(
        window.my_api + "api/commentus/get-commentus-by-id?contactId=" + this.id,
        {
          headers: {
            language: this.$i18n.locale,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.from = res.data.data;
         
       
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      })
      .catch(() => {
        alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
      });
  },

  methods: {
    onNamespaceLoaded( CKEDITOR ) {
        CKEDITOR.plugins.addExternal( 'video', '/layout/ckeditor/video/plugin.js' );
    },
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {

      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
          
      formData.append("subject",this.subject);
       formData.append("content",this.content);

        axios
          .post(
            window.my_api + "api/commentus/send-mail",
            formData
          )
          .then((res) => {
            if (res.status == 200) {
              this.$swal.fire({
                position: "top-end",
                icon: "success",
                title: "Đã gửi mail thành công.",
                showConfirmButton: false,
                timer: 1500,
              });
               this.$router.go(this.$router.currentRoute);
              document.getElementById("sendform").reset();
            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
            }
            this.is_load = false;
          });
      
    },
  },
};
</script>